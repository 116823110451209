<template>
  <div class="p-relative">
    <div 
      class="d-flex jsutify-content-between align-items-center toursim-sort-filter_btn mx-5"
      @click="toggleOption()"
    >
      <p>{{ $t('Filter') }}</p>
      <img src="/assets/img/icons/filter.png" alt="">
    </div>
    <perfect-scrollbar class="filter-block">
      <div class="p-2" v-show="isShowFilter">
        <div class="close-btn pointer" @click="toggleOption()">
          <img src="/assets/img/close.svg" alt />
        </div>
        <!-- <div class="filter-row">
          <div class="px-10">
            <p class="filter-title d-flex fs16">
              {{ $t('Stops') }}
            </p>
            <div class="d-flex align-items-center">
              <v-checkbox 
                color="#0D3856" 
                class="cus-checkbox" 
                id="non_stop"
              ></v-checkbox>
              <label for="non_stop" class="fs15">
                {{ $t('non-stop') }}
              </label>
            </div>
            <div class="d-flex align-items-center">
              <v-checkbox 
                color="#0D3856" 
                class="cus-checkbox" 
                id="1_stop"
              ></v-checkbox>
              <label for="1_stop" class="fs15">
                {{ $t('{NUM} stop', {NUM: 1}) }}
              </label>
            </div>
            <div class="d-flex align-items-center">
              <v-checkbox 
                color="#0D3856" 
                class="cus-checkbox" 
                id="2plus_stop"
              ></v-checkbox>
              <label for="2plus_stop" class="fs15">
                {{ $t('{NUM} stop', {NUM: '2+'}) }}
              </label>
            </div>
          </div>
        </div> -->
        <div class="filter-row">
          <div class="px-10">
            <p class="filter-title d-flex mt-7 fs16">
              {{ $t('Number of nights') }}
            </p>
            <div
              v-for="(item, index) in sideFilterData.nights"
              :key="index"
              class="d-flex align-items-center"
            >
              <v-checkbox 
                color="#0D3856" 
                class="cus-checkbox" 
                :id="item.label + '_night'"
                v-model="item.value"
              ></v-checkbox>
              <label :for="item.label + '_night'" class="fs15">
                {{ item.label }}
              </label>
            </div>
          </div>
        </div>
        <div class="filter-row">
          <div class="px-10">
            <p class="filter-title d-flex mt-7 fs16">
              {{ $t('Accommodation Star rating') }}
            </p>
            <div
              v-for="(item, index) in sideFilterData.stars"
              :key="index" 
              class="d-flex align-items-center"
            >
              <v-checkbox 
                color="#0D3856" 
                class="cus-checkbox" 
                :id="item.label + '_star'"
                v-model="item.value"
              ></v-checkbox>
              <label :for="item.label + '_star'" class="fs15 star-label">
                <RateStar :starNumber="item.label" v-if="item.label != ''"></RateStar>
                <span v-else>{{ $t('Non') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="filter-row">
          <div class="px-10">
            <p class="filter-title d-flex mt-7 fs16">
              {{ $t('Board basis') }}
            </p>
            <div 
              v-for="(item, index) in sideFilterData.roomType"
              :key="index"
              class="d-flex align-items-center">
              <v-checkbox 
                color="#0D3856" 
                class="cus-checkbox" 
                :id="item.label"
                v-model="item.value"
              ></v-checkbox>
              <label :for="item.label" class="fs15">
                {{ item.label }}
              </label>
            </div>
          </div>
        </div>
        <div class="filter-row" v-if="sideFilterData.priceSliderValue">
          <div class="px-10">
            <p class="filter-title d-flex mt-7 fs16 mb-10">
              {{ $t('Price range') }}
            </p>
            <p class="fs14 text-center mb-5">
              {{ sideFilterData.priceSliderValue[0] }}$ - {{ sideFilterData.priceSliderValue[1] }}$ 
            </p>
            <vue-slider 
              v-model="sideFilterData.priceSliderValue"
              :direction="sliderDirection"
              class="mb-5"
              tooltip="none"
              :min="sideFilterData.defaultPriceSliderValue[0]"
              :max="sideFilterData.defaultPriceSliderValue[1]"
            ></vue-slider>
          </div>
        </div>
        <div class="filter-row" v-if="sideFilterData.time">
          <div class="px-10">
            <p class="filter-title d-flex mt-7 fs16 mb-10">
              {{ $t('Times') }}
            </p>
            <div class="d-flex justify-content-center mb-15">
              <div class="time-type_btn" @click="activeTimeTab=1" :class="activeTimeTab==1 ? 'active' : ''">
                {{ $t('Take-off') }}
              </div>
              <div class="time-type_btn" @click="activeTimeTab=2" :class="activeTimeTab==2 ? 'active' : ''">
                {{ $t('Landing') }}
              </div>
            </div>
            <div v-if="activeTimeTab==1">
              <div class="text-center">
                <p class="fs14 text-center mb-2">
                  {{ $t('Take-off from {PLACE}', {PLACE: 'TLV'}) }}
                </p>
                <p class="fs14 text-center mb-5">
                  {{ changeNumberToTime(sideFilterData.time.takeOffTLV[0]) }} - {{ changeNumberToTime(sideFilterData.time.takeOffTLV[1]) }} 
                </p>
              </div>
              <vue-slider 
                v-model="sideFilterData.time.takeOffTLV"
                :direction="sliderDirection"
                class="mb-5"
                tooltip="none"
                :min="sideFilterData.time.defaultTakeOffTLV[0]"
                :max="sideFilterData.time.defaultTakeOffTLV[1]"
              ></vue-slider>
              <div class="text-center">
                <p class="fs14 text-center mb-2">
                  {{ $t('Take-off from {PLACE}', {PLACE: sideFilterData.des}) }}
                </p>
                <p class="fs14 text-center mb-5">
                  {{ changeNumberToTime(sideFilterData.time.takeOffDes[0]) }} - {{ changeNumberToTime(sideFilterData.time.takeOffDes[1]) }} 
                </p>
              </div>
              <vue-slider 
                v-model="sideFilterData.time.takeOffDes"
                :direction="sliderDirection"
                class="mb-5"
                tooltip="none"
                :min="sideFilterData.time.defaultTakeOffDes[0]"
                :max="sideFilterData.time.defaultTakeOffDes[1]"
              ></vue-slider>
            </div>
            <div v-else>
              <div class="text-center">
                <p class="fs14 text-center mb-2">
                  {{ $t('Landing from {PLACE}', {PLACE: 'TLV'}) }}
                </p>
                <p class="fs14 text-center mb-5">
                  {{ changeNumberToTime(sideFilterData.time.landingTLV[0]) }} - {{ changeNumberToTime(sideFilterData.time.landingTLV[1]) }} 
                </p>
              </div>
              <vue-slider 
                v-model="sideFilterData.time.landingTLV"
                :direction="sliderDirection"
                class="mb-5"
                tooltip="none"
                :min="sideFilterData.time.defaultLandingTLV[0]"
                :max="sideFilterData.time.defaultLandingTLV[1]"
              ></vue-slider>
              <div class="text-center">
                <p class="fs14 text-center mb-2">
                  {{ $t('Landing from {PLACE}', {PLACE: sideFilterData.des}) }}
                </p>
                <p class="fs14 text-center mb-5">
                  {{ changeNumberToTime(sideFilterData.time.landingDes[0]) }} - {{ changeNumberToTime(sideFilterData.time.landingDes[1]) }} 
                </p>
              </div>
              <vue-slider 
                v-model="sideFilterData.time.landingDes"
                :direction="sliderDirection"
                class="mb-5"
                tooltip="none"
                :min="sideFilterData.time.defaultLandingDes[0]"
                :max="sideFilterData.time.defaultLandingDes[1]"
              ></vue-slider>
            </div>
          </div>
          <div class="px-5">
            <div class="d-flex mt-10">
              <button class="primary-btn primary-btn_sm bg-pink" @click="clearFilter()">
                {{ $t('Clear filters') }}
              </button>
              <button class="primary-btn primary-btn_sm" @click="toggleOption()">
                {{ $t('Close') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RateStar from "../RateStar.vue";
export default {
  name: 'FilterTourism',
  components: {
    RateStar
  },
  props: {
    // isTourismDeal: Boolean
  },
  data() {
    return {
      isShowFilter: false,
      stars: [],
      activeTimeTab: 1
    }
  },
  computed: {
    ...mapState({
      sideFilterData: (state) => state.deal.sideFilterData
    }),
  },
  methods: {
    toggleOption() {
      this.isShowFilter = !this.isShowFilter;
      // $('.aside').toggleClass('aside_hidden');
      // this.toggleSideWindow();
    },
    // toggleSideWindow() {
    //   setTimeout(() => {
    //       if(document.getElementsByClassName('aside')[0].classList.contains("aside_hidden")) {
    //          console.log("test1")
    //           localStorage.setItem("isAside", false);
    //           localStorage.setItem("isOpenAside", false);
    //           document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
    //           document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
    //       } else {
    //          console.log("test2")
    //           localStorage.setItem("isOpenAside", true);
    //           if (this.$i18n.locale == "en") {
    //               document.getElementsByClassName('blank-aside-js')[0].style.marginRight = 0;
    //               document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = '250px';
    //           } else {
    //               document.getElementsByClassName('blank-aside-js')[0].style.marginLeft = 0;
    //               document.getElementsByClassName('blank-aside-js')[0].style.marginRight = '250px';
    //           }
    //       }
    //   }, 100);
    // },
    createTimeStr(timeValue, initialHour, initialMin) {
      let hour = Math.trunc(timeValue/60) + initialHour*1;
      let min = Math.trunc(timeValue%60) + initialMin*1;
      if (hour < 10) {
        hour = '0' + hour;
      }
      if (min < 10) {
        min = '0' + min;
      }
      return hour + ':' + min;
    },
    changeFilter() {
      const filterData = {
        stars: this.stars
      }
      this.$emit('changeFilter', filterData);
    },
    changeNumberToTime(num) {
      if (num) {
        let str = num.toString();
        const strLeng = str.length;
        if (strLeng == 4) {
          return str.substr(0, 2) + ":" + str.substr(-2);
        } else if (strLeng == 3) {
          return "0" + str.substr(0, 1) + ":" + str.substr(-2);
        } else if (strLeng == 2) {
          return "00" + ":" + str;
        } else if (strLeng == 1) {
          return "00" + ":" + "0" + str;
        } else if (strLeng == 0) {
          return "00" + ":" + "00";
        }
        return str.replace(':', '')*1;
      }

      return "---";
    },
    clearFilter() {
      this.sideFilterData.nights.forEach(el => {
        el.value = false;
      });
      this.sideFilterData.stars.forEach(el => {
        el.value = false;
      });
      this.sideFilterData.roomType.forEach(el => {
        el.value = false;
      });
      this.sideFilterData.priceSliderValue = this.sideFilterData.defaultPriceSliderValue;
      this.sideFilterData.time.takeOffTLV = this.sideFilterData.time.defaultTakeOffTLV;
      this.sideFilterData.time.takeOffDes = this.sideFilterData.time.defaultTakeOffDes;
      this.sideFilterData.time.landingTLV = this.sideFilterData.time.defaultLandingTLV;
      this.sideFilterData.time.landingDes = this.sideFilterData.time.defaultLandingDes;
    }
  }
}
</script>

<style lang="scss" scoped>
  .toursim-sort-filter_btn {
    cursor: pointer;
    background-color: #fff;
    height: 30px;
    padding: 0 5px;
    p {
      object-fit: contain;
      margin: 0;
    }
    img {
      padding: 0 5px;
      object-fit: contain;
      height: 90%;
    }
  }

  .filter-block {
    position: absolute;
    top: 33px;
    right: 10px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 21;
    width: 280px;
    // padding: 5px;
    max-height: 1300px;
    overflow-y: auto;
    .close-btn {
      display: flex;
      justify-content: flex-end;
      img {
        width: 15px;
        height: 15px;
      }
    }
    .cus-checkbox {
      margin-left: 15px;
      margin-right: 15px;
    }
    .filter-row {
      padding-bottom: 5px;
      border-bottom: 1px solid #BFBFBF;
      &:last-child {
        border-bottom: none;
      }
      .star-label {
        padding-top: 5px;
      }
      .time-type_btn {
        border: 1px solid #BFBFBF;
        background: #FFFFFF;
        color: #002036;
        opacity: 0.65;
        padding: 0 5px;
        font-size: 14px;
        cursor: pointer;
        &.active {
          background: #D9F4FF 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #BFBFBF;
          opacity: 1;
        }
      }
    }
  }
  .rtl-type {
     .filter-block {
       right: unset;
       left: 10px;
     }
  }
</style>