<template>
  <div 
    class="d-flex jsutify-content-between align-items-center toursim-sort-filter_btn p-relative"
    @click="toggleOption()"
  >
    <p class="fs16">{{ $t('Sort') }}</p>
    <img src="/assets/img/icons/sort.png" alt="">
    <div class="sort-block" v-show="isShowSort">
      <div class="sort-row fs14 space-nowrap" 
        @click="$emit('sortDeals', {'type': 'asc', 'isTourism': isTourismDeal})"
      >
        {{ $t('Price') }}: {{ $t('low to high') }}
      </div>
      <div class="sort-row fs14 space-nowrap" 
        @click="$emit('sortDeals', {'type': 'desc', 'isTourism': isTourismDeal})"
      >
        {{ $t('Price') }}: {{ $t('high to low') }}
      </div>
      <div class="sort-row fs14 space-nowrap" 
        @click="$emit('sortDeals', {'type': 'departure', 'isTourism': isTourismDeal})"
      >
        {{ $t('Departure date') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortTourism',
  props: {
    isTourismDeal: Boolean
  },
  data() {
    return {
      isShowSort: false
    }
  },
  methods: {
    toggleOption() {
      this.isShowSort = !this.isShowSort;
    },
  }
}
</script>

<style lang="scss" scoped>
  .toursim-sort-filter_btn {
    cursor: pointer;
    background-color: #fff;
    height: 30px;
    p {
      padding: 0 5px;
      object-fit: contain;
      margin: 0;
    }
    img {
      padding: 0 5px;
      object-fit: contain;
      height: 90%;
    }
  }

  .sort-block {
    position: absolute;
    top: 33px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 999;
    right: -50%;
  }
  .sort-row {
    padding: 5px 10px;
    border-bottom: 1px solid #d4d4d4;
  }
</style>